import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  CircularProgress,
  Paper,
  Snackbar,
  Alert,
  useMediaQuery,
  useTheme,
  Fab,
  Button,
  Box,
  IconButton,
} from '@mui/material';
import { format, startOfDay, endOfDay, isAfter, isBefore, endOfToday, set, differenceInDays } from 'date-fns';
import AddIcon from '@mui/icons-material/Add';
import BarChartIcon from '@mui/icons-material/BarChart';
import { getLedgerDetails, addTransaction, getLedgerTransactions, addCollaborator, updateTransaction, deleteTransaction, getLedgerCategories, deleteLedgerCategory, getExchangeRate, processVoiceInput } from '../services/api';
import TransactionEditModal from '../components/modals/TransactionEditModal';
import AddTransactionModal from '../components/modals/AddTransactionModal';
import AddCollaboratorModal from '../components/modals/AddCollaboratorModal';
import DeleteConfirmationModal from '../components/modals/DeleteConfirmationModal';
import DeleteCategoryConfirmationModal from '../components/modals/DeleteCategoryConfirmationModal';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import TransactionsSection from '../components/LedgerDetail/TransactionsSection';
import SimpleSummarySection from '../components/LedgerDetail/SimpleSummarySection';
import ManageIcon from '@mui/icons-material/Settings';
import MicIcon from '@mui/icons-material/Mic';

// Register the chart components
ChartJS.register(ArcElement, Tooltip, Legend);

function LedgerDetail() {
  const { id } = useParams();
  const [ledger, setLedger] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isCollaboratorModalOpen, setIsCollaboratorModalOpen] = useState(false);
  const [newCollaborator, setNewCollaborator] = useState('');
  const [newTransaction, setNewTransaction] = useState({
    amount: '',
    categoryModel: null,
    description: '',
    date: new Date(),
    type: 'expense',
    currency: 'USD',
    isRecurring: false,
    recurringType: null,
    recurringStartDate: null,
    recurringEndDate: null
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), 1);
  });
  const [endDate, setEndDate] = useState(endOfToday());
  const today = new Date();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deletingTransactionId, setDeletingTransactionId] = useState(null);

  const [categories, setCategories] = useState([]);

  const [deletingCategoryId, setDeletingCategoryId] = useState(null);
  const [isCategoryDeleteConfirmOpen, setIsCategoryDeleteConfirmOpen] = useState(false);

  const [exchangeRate, setExchangeRate] = useState(7.0); // Default to 1 if unable to fetch

  const navigate = useNavigate();

  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);

  useEffect(() => {
    fetchLedgerDetails();
    fetchTransactions();
    fetchCategories();
    fetchExchangeRate();
  }, [id, startDate, endDate]);

  const fetchLedgerDetails = async () => {
    try {
      const data = await getLedgerDetails(id);
      setLedger(data);
    } catch (err) {
      setError('Failed to fetch ledger details. Please try again.');
    }
  };

  const fetchTransactions = async () => {
    try {
      setLoading(true);
      const startDateISO = startOfDay(startDate).toISOString();
      const endDateISO = endOfDay(endDate).toISOString();
      
      const data = await getLedgerTransactions(id, startDateISO, endDateISO);
      const sortedTransactions = data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setTransactions(sortedTransactions);
      setError('');
    } catch (err) {
      setError('Failed to fetch transactions. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const data = await getLedgerCategories(id);
      setCategories(data);
      
      // Set default category after fetching
      if (data.length > 0) {
        const defaultCategory = data.find(cat => cat.type === 'expense');
        setNewTransaction(prev => ({ ...prev, categoryModel: defaultCategory }));
      }
    } catch (err) {
      console.error('Failed to fetch categories:', err);
    }
  };

  const fetchExchangeRate = async () => {
    try {
      // You'll need to implement this function in your api.js file
      const rate = await getExchangeRate('USD', 'CNY');
      setExchangeRate(rate);
    } catch (error) {
      console.error('Failed to fetch exchange rate:', error);
      // Keep the default rate of 1 if fetching fails
    }
  };

  const handleAddTransaction = async () => {
    try {
      // Check if a category is selected
      if (!newTransaction.categoryModel) {
        setSnackbar({
          open: true,
          message: 'Please select a category for the transaction.',
          severity: 'error'
        });
        return;
      }

      await addTransaction(id, newTransaction);
      setIsAddModalOpen(false);
      fetchTransactions();
      
      // Reset newTransaction, but keep the current type and set a default category
      const defaultCategory = categories.find(cat => cat.type === newTransaction.type);
      setNewTransaction({
        type: newTransaction.type,
        amount: '',
        currency: 'USD',
        description: '',
        categoryModel: defaultCategory
      });
      
      setSnackbar({
        open: true,
        message: 'Transaction added successfully',
        severity: 'success'
      });
    } catch (err) {
      setSnackbar({
        open: true,
        message: 'Failed to add transaction. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleAddCollaborator = async () => {
    try {
      await addCollaborator(id, newCollaborator);
      setIsCollaboratorModalOpen(false);
      fetchLedgerDetails();
      setNewCollaborator('');
      setSnackbar({
        open: true,
        message: 'Collaborator added successfully',
        severity: 'success'
      });
    } catch (err) {
      setSnackbar({
        open: true,
        message: err.message || 'Failed to add collaborator. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleStartDateChange = (newValue) => {
    if (isBefore(newValue, today) || newValue.toDateString() === today.toDateString()) {
      setStartDate(newValue);
      if (isAfter(newValue, endDate)) {
        setEndDate(newValue);
      }
    }
  };

  const handleEndDateChange = (newValue) => {
    if (isBefore(newValue, today) || newValue.toDateString() === today.toDateString()) {
      setEndDate(endOfDay(newValue));
      if (isBefore(newValue, startDate)) {
        setStartDate(startOfDay(newValue));
      }
    }
  };

  const handleEdit = (transaction) => {
    setEditingRecord({
      ...transaction,
      date: new Date(transaction.date),
      currency: transaction.currency // Ensure currency is included
    });
    setIsEditModalVisible(true);
  };

  const handleEditModalOk = async (event) => {
    if (event) {
      event.preventDefault();
    }    
    try {
      if (!editingRecord || !editingRecord._id) {
        throw new Error('Invalid transaction data');
      }

      // Get the current time
      const now = new Date();

      // Combine the selected date with the current time
      const combinedDate = set(editingRecord.date, {
        hours: now.getHours(),
        minutes: now.getMinutes(),
        seconds: now.getSeconds(),
        milliseconds: now.getMilliseconds()
      });

      // Format the date in ISO format with local timezone offset
      const formattedDate = format(combinedDate, "yyyy-MM-dd'T'HH:mm:ssxxx");

      const updatedRecord = {
        description: editingRecord.description,
        amount: editingRecord.amount,
        type: editingRecord.type,
        currency: editingRecord.currency,
        date: formattedDate,
        category: editingRecord.categoryModel._id
      };

      await updateTransaction(id, editingRecord._id, updatedRecord);
      setSnackbar({
        open: true,
        message: 'Transaction updated successfully',
        severity: 'success'
      });
      setIsEditModalVisible(false);
      fetchTransactions();
    } catch (error) {
      console.error('Failed to update transaction:', error);
      setSnackbar({
        open: true,
        message: 'Failed to update transaction. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false);
    setEditingRecord(null);
  };

  const handleDeleteClick = (transactionId) => {
    setDeletingTransactionId(transactionId);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (deletingTransactionId) {
      try {
        await deleteTransaction(id, deletingTransactionId);
        setSnackbar({
          open: true,
          message: 'Transaction deleted successfully',
          severity: 'success'
        });
        fetchTransactions();
      } catch (error) {
        console.error('Failed to delete transaction:', error);
        setSnackbar({
          open: true,
          message: 'Failed to delete transaction. Please try again.',
          severity: 'error'
        });
      }
    }
    setDeleteConfirmOpen(false);
    setDeletingTransactionId(null);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setDeletingTransactionId(null);
  };

  const handleDeleteCategoryConfirm = async () => {
    if (deletingCategoryId) {
      try {
        await deleteLedgerCategory(id, deletingCategoryId);
        
        // Refetch ledger details to update the category area
        await fetchLedgerDetails();
        await fetchCategories();

        setSnackbar({
          open: true,
          message: 'Category deleted successfully',
          severity: 'success'
        });
      } catch (error) {
        console.error('Failed to delete category:', error);
        setSnackbar({
          open: true,
          message: 'Failed to delete category. Please try again.',
          severity: 'error'
        });
      }
    }
    setIsCategoryDeleteConfirmOpen(false);
    setDeletingCategoryId(null);
  };

  const handleDeleteCategoryCancel = () => {
    setIsCategoryDeleteConfirmOpen(false);
    setDeletingCategoryId(null);
  };

  // Simplify the calculateTotal function
  const calculateTotal = (type) => {
    return transactions
      .filter(t => t.type === type)
      .reduce((sum, t) => sum + convertToUSD(t.amount, t.currency), 0)
      .toFixed(2);
  };

  // Keep the convertToUSD function
  const convertToUSD = (amount, currency) => {
    if (currency === 'USD') return amount;
    if (currency === 'CNY') return amount / exchangeRate;
    return amount; // Default case, should handle other currencies if needed
  };

  const calculateDailyAverage = (type) => {
    const total = parseFloat(calculateTotal(type));
    const days = differenceInDays(endDate, startDate) + 1; // +1 to include both start and end dates
    return (total / days).toFixed(2);
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      const chunks = [];

      recorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.push(e.data);
        }
      };

      recorder.onstop = async () => {
        const audioBlob = new Blob(chunks, { type: 'audio/mp3' });
        
        // 创建 FormData 对象
        const formData = new FormData();
        formData.append('audio', audioBlob);

        try {
          const response = await processVoiceInput(id, formData);
          if (response.success) {
            console.log("ai response: ", response)
            // 设置新交易数据并打开添加交易弹窗
            setNewTransaction({
              ...response.transaction,
              date: new Date(response.transaction.date)
            });
            setIsAddModalOpen(true);
          } else {
            setSnackbar({
              open: true,
              message: '语音识别失败',
              severity: 'error'
            });
          }
        } catch (error) {
          console.error('处理语音失败:', error);
          setSnackbar({
            open: true,
            message: '处理语音失败',
            severity: 'error'
          });
        }

        // 停止所有音轨
        stream.getTracks().forEach(track => track.stop());
      };

      recorder.start();
      setMediaRecorder(recorder);
      setIsRecording(true);

      setSnackbar({
        open: true,
        message: '开始录音...',
        severity: 'info'
      });
    } catch (error) {
      console.error('录音失败:', error);
      setSnackbar({
        open: true,
        message: '无法访问麦克风',
        severity: 'error'
      });
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      mediaRecorder.stop();
      setIsRecording(false);
      setSnackbar({
        open: true,
        message: '录音结束，正在处理...',
        severity: 'info'
      });
    }
  };

  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ mt: 4, textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="sm" sx={{ mt: 4, textAlign: 'center' }}>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container 
      maxWidth="md" 
      sx={{ 
        py: 1, 
        px: { xs: 1, sm: '12px' },
        '@media (min-width:600px)': {
          paddingLeft: '12px',
          paddingRight: '12px',
        }
      }}
    >
      <Typography variant="h5" component="h1" sx={{ mb: 2 }}>
        {ledger?.name}
      </Typography>

      <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2 }}>
        <Button
            variant="outlined"
            color="primary"
            startIcon={<BarChartIcon />}
            onClick={() => navigate(`/ledger/${id}/analysis`)}
          >
            Analysis
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ManageIcon />}
            onClick={() => navigate(`/ledger/${id}/manage`)}
          >
            Manage
          </Button>
        </Box>
      </Paper>

      <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
        <SimpleSummarySection 
          calculateTotal={calculateTotal} 
          calculateDailyAverage={calculateDailyAverage}
        />
        
        <TransactionsSection 
          isMobile={isMobile}
          setIsAddModalOpen={setIsAddModalOpen}
          startDate={startDate}
          endDate={endDate}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          today={today}
          loading={loading}
          transactions={transactions}
          handleEdit={handleEdit}
          handleDeleteClick={handleDeleteClick}
          exchangeRate={exchangeRate}
          convertToUSD={convertToUSD}
          categories={categories}
        >
          {!isMobile && (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => setIsAddModalOpen(true)}
              >
                Add Transaction
              </Button>
              <IconButton
                color={isRecording ? "error" : "primary"}
                onClick={isRecording ? stopRecording : startRecording}
                sx={{
                  border: 1,
                  borderColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.light',
                  },
                }}
              >
                <MicIcon />
              </IconButton>
            </Box>
          )}
        </TransactionsSection>
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ 
          vertical: isMobile ? 'bottom' : 'bottom', 
          horizontal: isMobile ? 'center' : 'center' 
        }}
        sx={{
          bottom: isMobile ? '96px !important' : '24px',
        }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {isMobile && (
        <>
          <Fab
            color="primary"
            aria-label="add"
            sx={{ position: 'fixed', bottom: 32, right: 16 }}
            onClick={() => setIsAddModalOpen(true)}
          >
            <AddIcon />
          </Fab>
          <Fab
            color={isRecording ? "error" : "primary"}
            aria-label="voice input"
            sx={{ position: 'fixed', bottom: 32, right: 80 }}
            onClick={isRecording ? stopRecording : startRecording}
          >
            <MicIcon />
          </Fab>
        </>
      )}

      <AddTransactionModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        newTransaction={newTransaction}
        setNewTransaction={setNewTransaction}
        handleAddTransaction={handleAddTransaction}
        categories={categories}
      />

      <AddCollaboratorModal
        isOpen={isCollaboratorModalOpen}
        onClose={() => setIsCollaboratorModalOpen(false)}
        newCollaborator={newCollaborator}
        setNewCollaborator={setNewCollaborator}
        handleAddCollaborator={handleAddCollaborator}
      />

      <TransactionEditModal
        isOpen={isEditModalVisible}
        onClose={handleEditModalCancel}
        editingRecord={editingRecord}
        setEditingRecord={setEditingRecord}
        onSave={handleEditModalOk}
        categories={categories}
      />

      <DeleteConfirmationModal
        isOpen={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        title="Confirm Delete"
        content="Are you sure you want to delete this transaction? This action cannot be undone."
      />

      <DeleteCategoryConfirmationModal
        isOpen={isCategoryDeleteConfirmOpen}
        onClose={handleDeleteCategoryCancel}
        onConfirm={handleDeleteCategoryConfirm}
      />
    </Container>
  );
}

export default LedgerDetail;