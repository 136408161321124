import React, { useState, useRef, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  useTheme,
  Slide,
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import NumericKeypad from '../NumericKeypad';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  '& .MuiToggleButton-root': {
    flex: '0 0 auto',
    padding: theme.spacing(0.5, 1),
    border: `1px solid ${theme.palette.divider}`,
    fontSize: '0.875rem',
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

function AddTransactionModal({ 
  isOpen, 
  onClose, 
  onConfirm, 
  categories,
  newTransaction,
  setNewTransaction,
  defaultType = 'expense',
  handleAddTransaction
}) {
  const [amountError, setAmountError] = useState('');
  const [isAmountFocused, setIsAmountFocused] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const amountInputRef = useRef(null);

  useEffect(() => {
    if (isOpen && isMobile) {
      setTimeout(() => {
        amountInputRef.current?.focus();
        setIsAmountFocused(true);
      }, 100);
    } else {
      setIsAmountFocused(false);
    }
  }, [isOpen, isMobile]);

  const handleAmountChange = (e) => {
    setNewTransaction({...newTransaction, amount: e.target.value});
    setAmountError('');
  };

  const handleAmountBlur = (e) => {
    if (e.relatedTarget && e.relatedTarget.closest('.numeric-keypad')) {
      return;
    }
    validateAmount(newTransaction?.amount);
    setIsAmountFocused(false);
  };

  const handleAmountFocus = () => {
    setIsAmountFocused(true);
  };

  const validateAmount = (inputNum) => {
    console.log("input: ", inputNum);
    const input = inputNum?.toString();
    
    try {
      const trimmedInput = input?.replace(/\s/g, '');
      if (!/^[\d+\-\.]+$/.test(trimmedInput)) {
        throw new Error('Invalid characters in input');
      }
      const result = eval(trimmedInput);
      if (isNaN(result) || result <= 0) {
        throw new Error('Result must be a positive number');
      }
      setNewTransaction({...newTransaction, amount: result});
      setAmountError('');
    } catch (error) {
      console.error('Invalid input:', error);
      setAmountError('Please enter a valid positive number');
    }
  };

  const handleKeypadInput = (value) => {
    if (value === 'backspace') {
      setNewTransaction({...newTransaction, amount: newTransaction.amount?.slice(0, -1)});

    } else {
      setNewTransaction({...newTransaction, amount: newTransaction.amount + value });
    }
    amountInputRef.current?.focus();
  };

  const handleKeypadClear = () => {
    setNewTransaction({...newTransaction, amount: ''});
    setAmountError('');
  };

  const hideKeyboard = () => {
    setIsAmountFocused(false);
    amountInputRef.current?.blur();
  };

  const handleDialogClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      hideKeyboard();
      onClose();
    }
  };

  const handleSubmit = () => {
    validateAmount(newTransaction?.amount);
    if (!amountError) {
      hideKeyboard();
      handleAddTransaction();
    }
  };

  const handleKeypadSubmit = () => {
    validateAmount(newTransaction?.amount);
    hideKeyboard();
  };

  const modalContent = (
    <>
      <Box mt={2}>
        <Typography variant="subtitle1">Type</Typography>
        <StyledToggleButtonGroup
          value={newTransaction.type}
          exclusive
          onChange={(e, newType) => {
            if (newType !== null) {
              const defaultCategory = categories.find(cat => cat.type === newType)?.categoryModel || null;
              setNewTransaction({...newTransaction, type: newType, categoryModel: defaultCategory});
            }
          }}
        >
          <ToggleButton value="income">Income</ToggleButton>
          <ToggleButton value="expense">Expense</ToggleButton>
        </StyledToggleButtonGroup>
      </Box>

      <TextField
        fullWidth
        margin="normal"
        label="Amount"
        value={newTransaction?.amount}
        onChange={handleAmountChange}
        onBlur={handleAmountBlur}
        onFocus={handleAmountFocus}
        error={!!amountError}
        helperText={amountError || "You can use +/- for calculations."}
        required
        InputProps={{
          readOnly: isMobile,
        }}
        inputRef={amountInputRef}
        inputProps={{
          inputMode: isMobile ? 'none' : 'numeric',
          pattern: isMobile ? undefined : '[0-9]*',
        }}
        autoFocus={!isMobile}
      />

      <FormControl fullWidth margin="normal">
        <InputLabel>Category</InputLabel>
        <Select
          value={newTransaction.categoryModel?._id || ''}
          label="Category"
          onChange={(e) => {
            const selectedCategory = categories.find(cat => cat._id === e.target.value);
            setNewTransaction(prev => ({
              ...prev,
              categoryModel: selectedCategory
            }));
          }}
        >
          {categories.filter(cat => cat.type === newTransaction.type).map((category) => (
            <MenuItem key={category._id} value={category._id}>
              {category.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      
      <FormControl fullWidth margin="normal">
        <InputLabel>Currency</InputLabel>
        <Select
          value={newTransaction.currency}
          label="Currency"
          onChange={(e) => setNewTransaction(prev => ({ ...prev, currency: e.target.value }))}
        >
          <MenuItem value="USD">USD</MenuItem>
          <MenuItem value="CNY">CNY</MenuItem>
        </Select>
      </FormControl>

      <TextField
        fullWidth
        margin="normal"
        label="Description"
        value={newTransaction.description}
        onChange={(e) => setNewTransaction(prev => ({ ...prev, description: e.target.value }))}
      />

      <Box mt={2}>
        <FormControlLabel
          control={
            <Switch
              checked={newTransaction.isRecurring || false}
              onChange={(e) => {
                setNewTransaction({
                  ...newTransaction,
                  isRecurring: e.target.checked,
                  recurringType: e.target.checked ? 'monthly' : null,
                  recurringStartDate: e.target.checked ? new Date() : null,
                  recurringEndDate: null
                });
              }}
            />
          }
          label="Recurring Transaction"
        />
      </Box>

      {newTransaction.isRecurring && (
        <Box mt={2}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="recurring-type-label">Recurring Type</InputLabel>
            <Select
              labelId="recurring-type-label"
              value={newTransaction.recurringType || 'monthly'}
              onChange={(e) => setNewTransaction({...newTransaction, recurringType: e.target.value})}
              label="Recurring Type"
            >
              <MenuItem value="monthly">Monthly</MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}
    </>
  );

  const handleDrawerClose = () => {
    hideKeyboard();
    onClose();
  };

  if (isMobile) {
    return (
      <Drawer
        anchor="bottom"
        open={isOpen}
        onClose={handleDrawerClose}
        PaperProps={{
          style: { height: '80%' },
        }}
      >
        <StyledAppBar>
          <Toolbar>
            <IconButton edge="start" onClick={handleDrawerClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Add New Transaction
            </Typography>
            <Button 
              onClick={handleSubmit}
              disabled={!newTransaction.categoryModel || !!amountError}
              sx={{
                color: theme.palette.primary.main,
                '&:disabled': {
                  color: theme.palette.action.disabled,
                },
              }}
            >
              Add
            </Button>
          </Toolbar>
        </StyledAppBar>
        <Box 
          p={2} 
          sx={{ 
            overflowY: 'auto', 
            height: 'calc(100% - 56px - 72px)' // 减去 AppBar 和底部按钮的高度
          }}
        >
          {modalContent}
        </Box>
        <Box 
          sx={{ 
            position: 'absolute', 
            bottom: 0, 
            left: 0, 
            right: 0, 
            p: 2, 
            borderTop: 1, 
            borderColor: 'divider',
            backgroundColor: 'background.paper'
          }}
        >
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSubmit}
            disabled={!newTransaction.categoryModel || !!amountError}
          >
            Add Transaction
          </Button>
        </Box>
        {isAmountFocused && (
          <Box 
            className="numeric-keypad" 
            sx={{ 
              position: 'absolute', 
              bottom: 72, // 为底部的 Add 按钮留出空间
              left: 0, 
              right: 0,
            }}
          >
            <NumericKeypad
              onInput={handleKeypadInput}
              onClear={handleKeypadClear}
              onSubmit={handleKeypadSubmit}
            />
          </Box>
        )}
      </Drawer>
    );
  }

  return (
    <Dialog 
      open={isOpen} 
      onClose={handleDialogClose}
      maxWidth="sm" 
      fullWidth
    >
      <DialogTitle>Add New Transaction</DialogTitle>
      <DialogContent>
        {modalContent}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>Cancel</Button>
        <Button 
          onClick={handleSubmit}
          color="primary"
          disabled={!newTransaction.categoryModel || !!amountError}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddTransactionModal;
