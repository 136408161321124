import axios from 'axios';

const API_URL = `${process.env.REACT_APP_REQUEST_DOMAIN ?? ""}/api`;

const getAuthHeader = () => ({
  Authorization: `Bearer ${localStorage.getItem('token')}`
});

const handle401Error = (error) => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem('token');
    window.location.href = '/auth';
  }
  throw error.response?.data || error;
};

async function sha256(message) {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
}

// Auth APIs
export const register = async (username, password) => {
  try {
    const hashedPassword = await sha256(password);
    const response = await axios.post(`${API_URL}/auth/register`, { username, password: hashedPassword });
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const login = async (username, password) => {
  try {
    const hashedPassword = await sha256(password);
    const response = await axios.post(`${API_URL}/auth/login`, { username, password: hashedPassword });
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const getUserProfile = async () => {
  try {
    const response = await axios.get(`${API_URL}/auth/profile`, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
  }
};

export const updateUserProfile = async (profileData) => {
  try {
    const response = await axios.patch(`${API_URL}/auth/profile`, profileData, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
  }
};

// Ledger APIs
export const getLedgers = async () => {
  try {
    const response = await axios.get(`${API_URL}/ledger`, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
  }
};

export const getLedgerDetails = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/ledger/${id}`, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
  }
};

export const createLedger = async (name) => {
  try {
    const response = await axios.post(`${API_URL}/ledger`, { name }, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
  }
};

export const deleteLedger = async (ledgerId) => {
  try {
    const response = await axios.delete(`${API_URL}/ledger/${ledgerId}`, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
  }
};

// Transaction APIs
export const addTransaction = async (ledgerId, transactionData) => {
  try {
    const response = await axios.post(`${API_URL}/ledger/${ledgerId}/transactions`, transactionData, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
  }
};

export const updateTransaction = async (ledgerId, transactionId, transactionData) => {
  try {
    const response = await axios.put(
      `${API_URL}/ledger/${ledgerId}/transactions/${transactionId}`,
      transactionData,
      { headers: getAuthHeader() }
    );
    return response.data;
  } catch (error) {
    handle401Error(error);
  }
};

export const deleteTransaction = async (ledgerId, transactionId) => {
  try {
    const response = await axios.delete(
      `${API_URL}/ledger/${ledgerId}/transactions/${transactionId}`,
      { headers: getAuthHeader() }
    );
    return response.data;
  } catch (error) {
    handle401Error(error);
  }
};

export const getLedgerTransactions = async (ledgerId, startDate, endDate) => {
  try {
    const response = await axios.get(`${API_URL}/ledger/${ledgerId}/transactions/range`, {
      headers: getAuthHeader(),
      params: { startDate, endDate }
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
  }
};

// Category APIs
export const getCategories = async () => {
  try {
    const response = await axios.get(`${API_URL}/ledger/categories`, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
  }
};

export const getLedgerCategories = async (ledgerId) => {
  try {
    const response = await axios.get(`${API_URL}/ledger/${ledgerId}/categories`, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
  }
};

export const addLedgerCategory = async (ledgerId, categoryData) => {
  try {
    const response = await axios.post(`${API_URL}/ledger/${ledgerId}/categories`, categoryData, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
  }
};

export const updateLedgerCategory = async (ledgerId, categoryId, categoryData) => {
  try {
    const response = await axios.put(`${API_URL}/ledger/${ledgerId}/categories/${categoryId}`, categoryData, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
  }
};

export const deleteLedgerCategory = async (ledgerId, categoryId) => {
  try {
    const response = await axios.delete(`${API_URL}/ledger/${ledgerId}/categories/${categoryId}`, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
  }
};

// Collaborator APIs
export const addCollaborator = async (ledgerId, collaboratorUsername) => {
  try {
    const response = await axios.post(`${API_URL}/ledger/${ledgerId}/collaborators`, 
      { username: collaboratorUsername },
      { headers: getAuthHeader() }
    );
    return response.data;
  } catch (error) {
    handle401Error(error);
  }
};

// Article APIs
export const getArticles = async (page = 1, limit = 10) => {
  try {
    const response = await axios.get(`${API_URL}/article`, {
      params: { page, limit }
    });
    return response.data;
  } catch (error) {
    return {
      success: false,
      data: {
        articles: [],
        pagination: {
          currentPage: 1,
          totalPages: 0,
          totalItems: 0,
          itemsPerPage: limit
        }
      }
    };
  }
};

export const getArticleById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/article/${id}`);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const createArticle = async (articleData) => {
  try {
    const response = await axios.post(`${API_URL}/article`, articleData, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
  }
};

export const updateArticle = async (id, articleData) => {
  try {
    const response = await axios.put(`${API_URL}/article/${id}`, articleData, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
  }
};

export const deleteArticle = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/article/${id}`, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
  }
};

// Media APIs
export const uploadImage = async (file) => {
  try {
    const formData = new FormData();
    formData.append('image', file);

    const response = await axios.post(`${API_URL}/media/upload`, formData, {
      headers: { 
        ...getAuthHeader(),
        'Content-Type': 'multipart/form-data'
      }
    });
    
    if (!response.data?.imageUrl) {
      throw new Error('No image URL in response');
    }
    
    return response.data.imageUrl;
  } catch (error) {
    handle401Error(error);
  }
};

// Novel APIs
export const fetchNovelContent = async (url, refresh = false) => {
  try {
    const response = await axios.post(`${API_URL}/novels/fetchContent`, { url, refresh });
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const getSupportedDomains = async () => {
  try {
    const response = await axios.get(`${API_URL}/novels/domains`);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

// Utility APIs
export const getExchangeRate = async (from, to) => {
  try {
    const response = await axios.get(`https://api.exchangerate-api.com/v4/latest/${from}`);
    return response.data.rates[to];
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const getLedgerRecurringTemplates = async (ledgerId) => {
  try {
    const response = await axios.get(`${API_URL}/ledger/${ledgerId}/recurring-templates`, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
  }
};

export const processVoiceInput = async (ledgerId, formData) => {
  try {
    const response = await fetch(`${API_URL}/ledger/${ledgerId}/voice-input`, {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData
    });
    
    if (!response.ok) {
      throw new Error('语音处理失败');
    }
    
    return await response.json();
  } catch (error) {
    console.error('API error:', error);
    throw error;
  }
};

export const textToSpeech = async (text, voice = 'nova') => {
  const mediaSource = new MediaSource();
  mediaSource.addEventListener("sourceopen", async () => {
    const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');
    const response = await fetch(`${API_URL}/novels/toVoice`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getAuthHeader()
      },
      body: JSON.stringify({ text, voice })
    });

    if (!response.ok) {
      throw new Error('Failed to convert text to speech');
    }

    const reader = response.body.getReader(); // 获取 ReadableStream 的读取器
    
    while (true) {
      const { value, done } = await reader.read();
      if (done) break;

      await appendBuffer(sourceBuffer, value);
    }

    mediaSource.endOfStream();
  })

  return URL.createObjectURL(mediaSource);

};

async function appendBuffer(sourceBuffer, chunk) {
  return new Promise((resolve, reject) => {
    if (sourceBuffer.updating) {
      // 等待上一次操作完成
      sourceBuffer.addEventListener("updateend", () => {
        appendChunk();
      }, { once: true });
    } else {
      appendChunk();
    }

    function appendChunk() {
      try {
        sourceBuffer.appendBuffer(chunk);
        sourceBuffer.addEventListener("updateend", resolve, { once: true });
        sourceBuffer.addEventListener("error", reject, { once: true });
      } catch (error) {
        reject(error);
      }
    }
  });
}